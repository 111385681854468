import React from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';

function waterCupsToGrams(cups) {
  return cups * 237;
}

export default () => {
  const [waterRatio, setWaterRatio] = React.useState(17);
  const [waterGrams, setWaterGrams] = React.useState(474);
  const [waterCups, setWaterCups] = React.useState(2);
  const [waterUnits, setWaterUnits] = React.useState('g');

  const gramsSelected = waterUnits === 'g';
  const waterInGrams = gramsSelected ? waterGrams : waterCupsToGrams(waterCups);

  return (
    <Layout>
      <Helmet>
        <title>Coffee Ratio Calculator</title>
      </Helmet>
      <h1>Coffee Ratio Calculator</h1>
      <p>
        Enter the ratio of <em>Coffee:Water</em> that you aim to brew and the
        total amount of water you want to use. The calculated values indicate
        how much coffee to grind and how much water to brew with. A good
        starting ratio for pourover is 1:17.
      </p>
      <div className="columns is-vcentered">
        <div className="column">
          <form>
            <label className="label" htmlFor="waterRatio">
              Coffee to Water Ratio (Coffee:Water)
            </label>
            <div className="field has-addons">
              <div className="control">
                <div className="button is-static">1:</div>
              </div>
              <div className="control">
                <input
                  id="waterRatio"
                  name="waterRatio"
                  className="input"
                  type="text"
                  placeholder="Water"
                  value={waterRatio}
                  onChange={e => setWaterRatio(e.target.value)}
                />
              </div>
            </div>
            <label className="label" htmlFor="water">
              Water Amount
            </label>
            <div className="field has-addons">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  id="water"
                  name="water"
                  value={gramsSelected ? waterGrams : waterCups}
                  onChange={e => {
                    gramsSelected
                      ? setWaterGrams(e.target.value)
                      : setWaterCups(e.target.value);
                  }}
                />
              </div>
              <div className="control">
                <span className="select">
                  <select
                    value={waterUnits}
                    onChange={e => setWaterUnits(e.target.value)}
                    onBlur={e => setWaterUnits(e.target.value)}
                  >
                    <option value="g">Grams</option>
                    <option value="C">Cups</option>
                  </select>
                </span>
              </div>
            </div>
          </form>
        </div>
        <div className="column">
          <table className="table is-bordered is-hoverable">
            <tbody>
              <tr>
                <td>
                  <strong>Water Amount</strong>
                </td>
                <td>{_.round(waterInGrams)} g</td>
              </tr>
              <tr>
                <td>
                  <strong>Coffee Amount</strong>
                </td>
                <td>{_.round(waterInGrams / waterRatio)} g</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};
